<template>
  <v-dialog
    v-model="isOpened"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-container>
        <v-form @submit.prevent="onSubmit" v-model="valid">
          <v-card-text>
            <Datepicker
              title="Дата"
              v-model="modalIncome.datetime"
              :clearable="true"
            />

            <v-select
              label="Тип"
              required
              item-value="id"
              item-text="name"
              :items="types"
              v-model="modalIncome.income_expenses_type_id"
              @input="resetErrors"
              :error-messages="errors.income_expenses_type_id"
            />

            <v-text-field
              label="Сумма"
              required
              type="number"
              v-model="modalIncome.amount"
              @input="resetErrors"
              :error-messages="errors.amount"
            />

            <v-textarea
              label="Комментарий"
              required
              v-model="modalIncome.comment"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />

            <v-btn
              color="blue darken-1"
              text
              @click="onCancelClick"
            >
              Отмена
            </v-btn>

            <v-btn
              color="blue darken-1"
              outlined
              type="submit"
              :disabled="!valid"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { IncomeExpensesService } from '@/common/api.service'
import Datepicker from '@/components/Datepicker'

export default {
  props: {
    income: {
      default: null
    },
    types: {
      default: []
    }
  },

  components: {
    Datepicker
  },

  data() {
    return {
      modalIncome: {
        id: null,
        income_expenses_type_id: null,
        amount: null,
        comment: null,
        datetime: null
      },
      isOpened: false,
      valid: true,
      errors: {},
    }
  },

  methods: {
    onSubmit() {
      if (this.income.id ) {
        IncomeExpensesService.update(this.modalIncome).then(() => this.$emit('close', true))
      } else {
        IncomeExpensesService.store(this.modalIncome)
          .then(() => this.$emit('close', true))
          .catch(error => {
            if (error.response && 422 === error.response.status) {
              this.errors = error.response.data.errors
            }
          })
      }
    },

    onCancelClick() {
      this.$emit('close', false)
    },

    resetErrors () {
      this.errors = {}
    }
  },

  watch: {
    income (value) {
      if (value) {
        this.isOpened = true
        for (const [key] of Object.entries(this.modalIncome)) {
          this.modalIncome[key] = this.income[key]
        }
      } else {
        this.isOpened = false
      }
    }
  }
}
</script>
