<template>
  <v-row>
    <v-col
      cols="12"
    >
      <v-data-table
        :headers="headers"
        :items="income_expenses"
        :server-items-length="income_expenses_total"
        :options.sync="opts"
        class="elevation-1"
        :footer-props="{ itemsPerPageOptions: [5, 10, 20, 50] }"
        :items-per-page="20"
        sort-by="created_at"
        sort-desc
        :item-class="itemRowBackground"
        show-expand
      >
        <template v-slot:top>
          <div class="ma-2">
            <v-btn
              color="success"
              @click="incomeEditId = 0"
            >
              <v-icon left>
                mdi-plus
              </v-icon>
              Приход
            </v-btn>
            <v-btn
              color="warning"
              @click="expensesEditId = 0"
              class="ml-2"
            >
              <v-icon left>
                mdi-plus
              </v-icon>
              Расход
            </v-btn>

            <span class="ml-3">
              Сумма: {{ amount }} руб.
            </span>

            <span class="ml-3">
              Приход: {{ incomeTotal }} руб.
            </span>

            <span class="ml-3">
              Расход: {{ expensesTotal }} руб.
            </span>
          </div>

          <v-container fluid>
            <v-row>
              <v-col cols="3">
                <Datepicker
                  title="Дата от"
                  v-model="opts.date_from"
                  :clearable="true"
                />
              </v-col>

              <v-col cols="3">
                <Datepicker
                  title="Дата до"
                  v-model="opts.date_to"
                  :clearable="true"
                />
              </v-col>

              <v-col cols="3">
                <v-select
                  label="Тип"
                  :items="[...incomes, ...expenses]"
                  item-value="id"
                  item-text="name"
                  v-model="opts.income_expenses_type_id"
                  clearable
                  multiple
                />
              </v-col>

              <v-col cols="3">
                <v-select
                  label="Пользователь"
                  :items="users"
                  item-value="id"
                  v-model="opts.user_id"
                  clearable
                  multiple
                >
                  <template #item="{ item }">
                    {{ getRole(item) }} {{ item.name }}
                  </template>

                  <template #selection="{ item }">
                    {{ getRole(item) }} {{ item.name }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:item.income_expenses_type_id="{ item }">
          {{ item.income_expenses_type ? item.income_expenses_type.name : '' }}
        </template>
        <template v-slot:item.user_id="{ item }">
          {{ item.user ? item.user.name : '-' }}
        </template>

        <template #expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-simple-table v-if="item.verification" class="list__table mb-4">
              <template #default>
                <tbody>
                <tr>
                  <td>Адрес</td>
                  <td>{{ item.verification.address }}</td>
                </tr>

                <tr>
                  <td>Имя</td>
                  <td>{{ item.verification.name }}</td>
                </tr>

                <tr>
                  <td>Телефон</td>
                  <td>{{ item.verification.phone }}</td>
                </tr>

                <tr>
                  <td>
                    <router-link :to="`/filial_manager/verifications/edit/${item.verification.id}`">
                      Перейти к заявке
                    </router-link>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-simple-table v-else-if="item.mileage" class="list__table mb-4">
              <template #default>
                <tbody>
                <tr>
                  <td>Пробег</td>
                  <td>{{ item.mileage.finish ? item.mileage.finish - item.mileage.start : '-' }}</td>
                </tr>

                <tr>
                  <td>Имя</td>
                  <td>{{ item.mileage.user ? item.mileage.user.name : '-' }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-col>

    <expenses-modal
      :expenses="expensesModalItem"
      :types="expenses"
      :users="users"
      @close="onModalClose"
    />

    <income-modal
      :income="incomeModalItem"
      :types="incomes"
      @close="onModalClose"
    />
  </v-row>
</template>

<script>
import { IncomeExpensesService, TypesService, UsersService } from '@/common/api.service'
import IncomeModal from '@/views/FilialManager/IncomeModal'
import ExpensesModal from '@/views/FilialManager/ExpensesModal'
import Datepicker from '@/components/Datepicker'

export default {
  components: {
    IncomeModal,
    ExpensesModal,
    Datepicker
  },

  data () {
    return {
      incomes: [],
      expenses: [],
      users: [],
      amount: 0,
      incomeTotal: 0,
      expensesTotal: 0,
      income_expenses: [],
      income_expenses_total: 0,
      incomeEditId: null,
      expensesEditId: null,
      headers: [
        {
          text: 'Дата',
          value: 'datetime',
          align: 'left'
        },
        {
          text: 'Дата создания',
          value: 'created_at',
          sortable: false
        },
        {
          text: 'Сумма',
          value: 'amount'
        },
        {
          text: 'Тип',
          value: 'income_expenses_type_id'
        },
        {
          text: 'Пользователь',
          value: 'user_id'
        },
        {
          text: 'Комментарий',
          value: 'comment'
        }
      ],
      opts: {
        sortBy: ['created_at'],
        sortDesc: [true],
        itemsPerPage: 20,
        page: 1,
        date_from: null,
        date_to: null,
        income_expenses_type_id: null,
        user_id: null
      }
    }
  },

  beforeMount () {
    TypesService
      .getIncomeExpenses()
      .then((response) => {
        this.incomes = response.data.types.filter((type) => type.is_income === 1)
        this.expenses = response.data.types.filter((type) => type.is_income === 0)
      })

    UsersService
      .workers(false, this.$store.state.auth.user.region_id)
      .then(({ data }) => {
        this.users = data.users
      })
  },

  computed: {
    incomeModalItem() {
      return this.incomeEditId !== 0 ? this.income_expenses.find(income => income.id === this.incomeEditId) : {}
    },
    expensesModalItem() {
      return this.expensesEditId !== 0 ? this.income_expenses.find(expenses => expenses.id === this.expensesEditId) : {}
    }
  },

  methods: {
    fetchIncomeExpenses () {
      IncomeExpensesService
        .getIncomeExpenses(this.opts)
        .then(({ data }) => {
          this.income_expenses = data.data
          this.income_expenses_total = data.meta.total
          this.amount = data.amount
          this.incomeTotal = data.income
          this.expensesTotal = data.expenses
        })
    },

    onModalClose (needRefresh) {
      this.incomeEditId = null
      this.expensesEditId = null

      if (needRefresh) {
        this.fetchIncomeExpenses()
      }
    },

    // Классы строк таблицы в зависимости от статуса поверки
    itemRowBackground (item) {
      if (item.payed_at) {
        return 'yellow lighten-4'
      }

      switch (item.income_expenses_type.is_income) {
        case 1:
          return 'green lighten-4'
        case 0:
          return 'red lighten-4'
        default:
          return ''
      }
    },

      getRole (user) {
        switch (user.role) {
          case 'manager':
            return 'Менеджер'
          case 'company':
            return 'Газпром'
          case 'director':
            return 'Директор'
          case 'coordinator':
            return 'Координатор'
          case 'filial_manager':
            return 'Начальник'
          case 'worker':
            return  user.worker_type_id === 1 ? 'Поверщик' : 'Слесарь'
        }

        return ''
      }
  },

  watch: {
    opts: {
      handler (newOpts, oldOpts) {
        if (newOpts.page === oldOpts.page) {
          this.opts.page = 1
        }

        this.fetchIncomeExpenses()
      },
      deep: true
    }
  }
}
</script>
